<!--
 * @Autor: oops.liu
 * 退款进度详情
-->
<template>
  <div class="progress_box">
    <div class="content_box">
      <scroller>
        <div class="content">
          <van-steps
            direction="vertical"
            :active="5"
            :active-icon="status ? success : 'cross'"
          >
            <van-step v-for="(item, index) in list" :key="index">
              <div class="progress_title">{{ item.title }}</div>
              <div class="progress_hint">{{ item.info }}</div>
              <p class="progress_time">{{ item.time }}</p>
            </van-step>
          </van-steps>
        </div>
      </scroller>
    </div>
  </div>
</template>

<script>
import handleLeft from "@/mixin/handleLeft.js";
export default {
  components: {},
  mixins: [handleLeft],
  props: {},
  data() {
    return {
      // 进度状态
      status: true,
      list: [
        {
          title: "退款成功",
          info: "您的服务单号422485754已退款，请注意查收",
          time: "2021-05-17  10:22:11",
        },
        {
          title: "退款申请售后审核通过",
          info: "您的服务单业务主管已审核，请待财务确认",
          time: "2021-05-17  10:22:11",
        },
        {
          title: "退款申请",
          info: "你的服务单已提交退款申请",
          time: "2021-05-17  10:22:11",
        },
        {
          title: "检测中",
          info: "你的服务单号422485754的商品检测中",
          time: "2021-05-17  10:22:11",
        },
        {
          title: "商品商家售后已收到",
          info: "你的服务单号422485754的商品已收到",
          time: "2021-05-17  10:22:11",
        },
        {
          title: "审核留言",
          info: "尊敬的商城用户，商品自收到之日起7天内没有损坏的情况下，我们提供无理由退货服务，请与专属客服协商后回寄的快递名称及快递单号、服务单号报与客服记录后，请确保商品全套及发票原件完整，如实际收货发现商品已损坏我们将商品原物返回，感谢您的支持。有问题可以咨询客服，温馨提示：为避免数据遗失或泄露，请您提前做好数据备份",
          time: "2021-05-17  10:22:11",
        },
        {
          title: "等待审核",
          info: "你的服务单已经申请成功，待售后审核中",
          time: "2021-05-17  10:22:11",
        },
        {
          title: "问题描述",
          info: "商品与描述不符，申请退货退款",
          time: "2021-05-17  10:22:11",
        },
      ],
      success: require("@/assets/img/index/success.png"),
    };
  },
  created() {},
};
</script>

<style scoped lang="scss">
.progress_box {
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  // .nav_bar_box {
  //   ::v-deep .van-nav-bar {
  //     .van-nav-bar__left {
  //       .van-icon {
  //         color: #666;
  //       }
  //     }
  //   }
  // }
  .content_box {
    position: relative;
    width: 100%;
    // height: calc(100vh - 46px);
    height: 100%;
    .content {
      width: 100%;
      box-sizing: border-box;
      padding: 20px 18px;
      ::v-deep .van-steps {
        background: transparent;
        .van-step {
          padding-bottom: 30px;
          padding-left: 30px;
          padding-right: 30px;
          .van-step__circle {
            background: #cccccc;
          }
          .van-step__line {
            background: #cccccc;
          }
        }
        .van-step--finish {
          .van-step__circle {
            background: #cccccc;
          }
          .van-step__line {
            background: #cccccc;
          }
        }

        .van-icon-success {
          border-radius: 50%;
        }
        .van-step__circle-container {
          left: 10px;
          .van-step__icon--active {
            font-size: 16px;
          }
        }
        .van-hairline {
          .van-step__line {
            left: 10px;
            top: 0px;
            height: 100%;
            width: 1px;
          }
        }
        .van-step__circle {
          width: 10px;
          height: 10px;
        }
        .van-hairline {
          &:first-of-type {
            .van-step__line {
              left: 10px;
              top: unset;
              bottom: 0;
              height: calc(100% - 19px);
            }
          }
          &:last-of-type {
            .van-step__line {
              left: 10px;
              top: 0px;
              height: 19px;
            }
          }
        }
      }
      .progress_title {
        color: #000;
        font-size: 16px;
      }
      .progress_hint {
        font-size: 12px;
        padding: 5px 0;
        color: #666;
      }
      .progress_time {
        font-size: 11px;
        color: #999;
      }
    }
  }
}
</style>
